import { Box, Divider, HStack, Link as ChakraLink, Stack, useColorModeValue } from '@chakra-ui/react'
import { BiExtension, BiBookOpen, BiHelpCircle, BiCalendarAlt } from 'react-icons/bi'
import { FaSlack } from 'react-icons/fa'
import { NavLink } from 'react-router-dom'

import NavButton from './navButton'

import useCustomerSubscription from '@app/hooks/useCustomerSubscription'
import useGetObjectsByProperties from '@app/hooks/useGetObjectsByProperties'
import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import Can from '@app/shared/authorization/can'
import { abilitiesFor } from '@app/shared/authorization/caslProvider'
import FavoritesSidebarList from '@app/shared/layout/shells/components/favoritesSidebarList'
import FreeTrialAlert from '@app/shared/layout/shells/components/freeTrialAlert'
import GoogleSheetSidebarMenu from '@app/shared/layout/shells/components/googleSheetSidebarMenu'
import SidebarMenu from '@app/shared/layout/shells/components/sidebarMenu'
import ICON_MAP from '@app/utils/iconMap'
import { Routes } from '@app/utils/routeHelpers'

const Sidebar = () => {
  const { user } = useStoreCurrentUser()

  const subscription = useCustomerSubscription()

  const abilities = abilitiesFor(user)
  const canUpdateMetric = abilities.can('update', 'metric')
  const showListLinks = canUpdateMetric
  const googleSheet = useGetObjectsByProperties('googleSheet', { organizationLevel: true })?.[0]

  const maxWidth = 'xs'

  return (
    <Stack
      as="section"
      justifyContent="space-between"
      direction="column"
      w={maxWidth}
      minH="100vh"
      bg="bg.surface"
      shadow={useColorModeValue('sm', 'sm-dark')}
    >
      <Box
        overflowY="auto"
        maxW={{ base: 'full', sm: maxWidth }}
        px={{ base: '4', sm: '6' }}
        py={{ base: '6', sm: '8' }}
      >
        <Stack spacing={{ base: '4', sm: '6' }}>
          <NavButton as={NavLink} label="Maps" icon={ICON_MAP.StrategyMap} to="/strategy" />
          {showListLinks ? (
            <>
              <Divider />
              <Stack spacing="1">
                <NavButton as={NavLink} label="Cards" icon={ICON_MAP.BasicCard} to="/cards" />
                {canUpdateMetric && (
                  <HStack>
                    <NavButton flex="1" as={NavLink} label="Metrics" icon={ICON_MAP.Metric} to="/metrics" />
                    {googleSheet && <GoogleSheetSidebarMenu googleSheet={googleSheet} />}
                  </HStack>
                )}
                <NavButton as={NavLink} label="Work" icon={ICON_MAP.Work} to="/work" />
              </Stack>
            </>
          ) : null}
          <Divider />
          <FavoritesSidebarList />
          <Stack spacing={1}>
            <Can I="update" an="organization">
              <NavButton as={NavLink} label="Playbooks" icon={ICON_MAP.Playbook} to="/playbooks" />
              <NavButton as={NavLink} label="Integrations" to="/settings/integrations" icon={BiExtension} />
            </Can>
            <NavButton label="Schedule a chat" href={Routes.chat} icon={BiCalendarAlt} as={ChakraLink} isExternal />
            <NavButton label="Slack community" href={Routes.slackCommunity} icon={FaSlack} as={ChakraLink} isExternal />

            {/* <NavButton */}
            {/*  as={ChakraLink} */}
            {/*  label="Our changelog" */}
            {/*  href={Routes.doubleloopProductUpdates} */}
            {/*  icon={BiListCheck} */}
            {/*  isExternal */}
            {/* /> */}
            <NavButton as={ChakraLink} label="Documentation" href={Routes.docs} icon={BiBookOpen} isExternal />
            <NavButton id="intercom-launcher" label="Need help?" icon={BiHelpCircle} />
          </Stack>
        </Stack>
      </Box>
      <Stack>
        {subscription && <FreeTrialAlert subscription={subscription} />}
        {user && <SidebarMenu user={user} />}
      </Stack>
    </Stack>
  )
}

export default Sidebar
